import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"; // Include the default styles
// import pic1 from '../1.jpg'
// import pic2 from '../2.jpg'
import pic1 from '../gallery_1.jpg'
import pic2 from '../gallery_2.jpg'
import pic3 from '../3.jpg'
import pic4 from '../4.jpg'
import pic5 from '../5.jpg'
import pic6 from '../6.jpg'
import pic7 from '../7.png'
import pic8 from '../8.jpg'
import pic9 from '../9.jpg'
import pic10 from '../10.jpg'
// import pic11 from '../11.jpg'
import pic11 from '../gallery_3.jpg'
import pic12 from '../12.jpg'
import pic13 from '../13.jpg'
import pic14 from '../14.jpg'
import pic15 from '../15.jpg'
import pic16 from '../16.jpg'
import pic17 from '../17.jpg'
import pic18 from '../18.jpg'
import pic19 from '../19.jpg'
import pic20 from '../20.jpg'
import pic21 from '../21.jpg'
import pic23 from '../23.jpg'
import pic24 from '../24.jpg'
import pic25 from '../25.jpg'
import pic26 from '../26.jpg'
import pic27 from '../27.jpg'
import pic28 from '../28.jpg'
import pic29 from '../29.jpg'
import pic30 from '../30.jpg'
import pic31 from '../31.jpg'
import pic32 from '../32.jpg'
import pic33 from '../33.jpg'
import pic34 from '../34.jpg'
import pic35 from '../35.jpg'
import pic36 from '../36.jpg'
import pic37 from '../37.jpg'

const images = [
    {
      original: pic1,
      thumbnail: pic1,
    },
    {
      original: pic2,
      thumbnail: pic2,
    },
    {
      original: pic11,
      thumbnail: pic11,
    },
    {
      original: pic3,
      thumbnail: pic3,
    },
    {
      original: pic4,
      thumbnail: pic4,
    },
    {
      original: pic5,
      thumbnail: pic5,
    },
    {
      original: pic6,
      thumbnail: pic6,
    },
    {
      original: pic7,
      thumbnail: pic7,
    },
    {
      original: pic8,
      thumbnail: pic8,
    },
    {
      original: pic9,
      thumbnail: pic9,
    },
    {
      original: pic10,
      thumbnail: pic10,
    },
    {
      original: pic12,
      thumbnail: pic12,
    },
    {
      original: pic13,
      thumbnail: pic13,
    },
    {
      original: pic14,
      thumbnail: pic14,
    },
    {
      original: pic15,
      thumbnail: pic15,
    },
    {
      original: pic16,
      thumbnail: pic16,
    },
    {
      original: pic17,
      thumbnail: pic17,
    },
    {
      original: pic18,
      thumbnail: pic18,
    },
    {
      original: pic19,
      thumbnail: pic19,
    },
    {
      original: pic20,
      thumbnail: pic20,
    },
    {
      original: pic21,
      thumbnail: pic21,
    },
    {
      original: pic23,
      thumbnail: pic23,
    },
    {
      original: pic24,
      thumbnail: pic24,
    },
    {
      original: pic25,
      thumbnail: pic25,
    },
    // {
    //   original: pic26,
    //   thumbnail: pic26,
    // },
    // {
    //   original: pic27,
    //   thumbnail: pic27,
    // },
    {
      original: pic28,
      thumbnail: pic28,
    },
    // {
    //   original: pic29,
    //   thumbnail: pic29,
    // },
    {
      original: pic30,
      thumbnail: pic30,
    },
    {
      original: pic31,
      thumbnail: pic31,
    },
    {
      original: pic32,
      thumbnail: pic32,
    },
    {
      original: pic33,
      thumbnail: pic33,
    },
    {
      original: pic34,
      thumbnail: pic34,
    },
    {
      original: pic35,
      thumbnail: pic35,
    },
    {
      original: pic36,
      thumbnail: pic36,
    },
    {
      original: pic37,
      thumbnail: pic37,
    },
  ];

class CarouselComponent extends Component {
    render() {
        return (
            <div>
                <ImageGallery items={images} lazyLoad={true} additionalClass="custom-gallery"/>
            </div>
        );
    }
}

export default CarouselComponent;